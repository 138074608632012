@include bem-block(external-content) {
  grid-area: content;
}

@each $key in $theme-color-keys {
  @include bem-block(external-content, $modifier: theme-#{$key}) {
    @include theme-background-color($key);
  }
}

@include bem-block(external-content, $modifier: brand-cream) {
  background-color: brand-color('cream');
}
